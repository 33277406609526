// src/templates/page.js
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import typographyStyles from "../styles/typography.module.scss"
import buttonStyles from "../styles/buttons.module.scss"
import elementStyles from "../styles/elements.module.scss"
import pageAboutStyles from "../styles/pageAbout.module.scss"

const PageTemplate = ({ data }) => (

  <Layout>
    <SEO
      title={data.wpgraphql.pageBy.seo.title}
      description={data.wpgraphql.pageBy.seo.metaDesc}
    />
    <section className={pageAboutStyles.pageIntro}>
      <div className={`${elementStyles.container} ${elementStyles.medium}`}>
        <header className={pageAboutStyles.pageHeader}>
          <h1 className="page-title">{ data.wpgraphql.pageBy.about_content.titleOverride || data.wpgraphql.pageBy.title }</h1>
        </header>
        <div className={pageAboutStyles.contentArea} dangerouslySetInnerHTML={{ __html: data.wpgraphql.pageBy.content }} />
        <div className="button-row">
          {data.wpgraphql.pageBy.about_content.cta.linkType === 'External' && (
            <a
              href={data.wpgraphql.pageBy.about_content.cta.externalUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={buttonStyles.buttonForward}
            >
              {data.wpgraphql.pageBy.about_content.cta.buttonText}
            </a>
          )}
          {data.wpgraphql.pageBy.about_content.cta.linkType === 'Internal' && (
            <Link
              to={new URL(data.wpgraphql.pageBy.about_content.cta.pageLink.link).pathname}
              className={buttonStyles.buttonForward}
            >
              {data.wpgraphql.pageBy.about_content.cta.buttonText}
            </Link>
          )}
        </div>
      </div>
    </section>
    <section className={pageAboutStyles.skillsets}>
      <div className={elementStyles.container}>
        {data.wpgraphql.pageBy.about_content.skillsets.map((skillset,i) => (
          <div key={'skillblock_' + i} className={pageAboutStyles.skillBlock}>
            <div className={pageAboutStyles.skillBlockTitle}>
              <i className={skillset.iconClass}></i>
              <h2>{skillset.name}</h2>
            </div>
            <div className={pageAboutStyles.skillBlockContent}>
              {skillset.skills.map((skill,i) => (
                <span key={'skill_' + i}>
                  {skill.name}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  </Layout>
)
export default PageTemplate
export const query = graphql`
  query($id: Int!) {
    wpgraphql {
      pageBy(pageId: $id) {
        title
        excerpt
        content
        seo {
          title
          metaDesc
        }
        about_content {
          titleOverride
          cta {
            buttonText
            externalUrl
            pageLink {
              ... on WPGraphQL_Page {
                link
                pageId
              }
            }
            linkType
          }
          skillsets {
            name
            iconClass
            skills {
              name
            }
          }
        }
      }
    }
  }
`
